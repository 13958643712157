<template>
  <div class="add-instrument">
    <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-width="120px">
      <el-button type="text" class="back-btn" @click="$router.go(-1)">
        <i class="el-icon-arrow-left"></i> 返回
      </el-button>
      <div class="module">
        <h3 class="title">仪器基本信息：</h3>
        <div class="content">
          <el-row>
            <el-col :span="10">
              <el-form-item label="仪器分类编码:" prop="code">
                <el-input class="com-input60" v-model="ruleForm.code"></el-input>
              </el-form-item>
              <el-form-item label="仪器名称:" prop="name">
                <el-input class="com-input90" v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item label="英文名称:" prop="english_name">
                <el-input class="com-input90" v-model="ruleForm.english_name"></el-input>
              </el-form-item>
              <el-form-item label="规格型号:" prop="model">
                <el-input class="com-input90" v-model="ruleForm.model"></el-input>
              </el-form-item>
              <el-form-item label="所属单位:" prop="company">
                <el-input class="com-input90" v-model="ruleForm.company"></el-input>
              </el-form-item>
              <el-form-item label="生产厂商:" prop="manufacturer">
                <el-input class="com-input90" v-model="ruleForm.manufacturer"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="仪器图片:" prop="image">
                <el-upload
                  class="avatar-uploader"
                  :action="URL.upLoad"
                  :show-file-list="false"
                  :on-success="uploadSuccess"
                  :before-upload="beforeUpload"
                  accept="image/jpeg, image/png, image/jpg"
                >
                  <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span class="image-tag">只支持jpg、jpeg、png格式，大小不超过2M，建议尺寸：200*200</span>
              </el-form-item>
              <el-form-item label="仪器分类:" prop="category">
                <el-cascader
                  class="com-input60"
                  v-model="ruleForm.category"
                  :options="instCategories"
                  @change="categoryChange"
                  :props="props"
                  clearable
                ></el-cascader>
                <el-button type="primary" style="margin-left:3px" @click="myOrder">自定义</el-button>
              </el-form-item>
              <el-form-item label="仪器安放地址:" prop="address">
                <el-input class="com-input60" v-model="ruleForm.address"></el-input>
              </el-form-item>
              <el-form-item label="产地国别:" prop="country">
                <el-input class="com-input60" v-model="ruleForm.country"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="display:flex;justify-content:space-between">
            <el-form-item label="购置日期:" label-width="85px" prop="get_date">
              <el-date-picker
                v-model="ruleForm.get_date"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="仪器原值:" prop="value" label-width="85px">
              <el-input class="com-select100" v-model="ruleForm.value"></el-input>
              <el-select style="width:70px" class="com-select100" v-model="ruleForm.value_unit">
                <el-option label="元" value="元"></el-option>
                <el-option label="万元" value="万元"></el-option>
                <el-option label="千万" value="千万"></el-option>
                <el-option label="亿" value="亿"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工作状态:" prop="work_status" label-width="85px">
              <el-select class="com-select110" v-model="ruleForm.work_status" placeholder="仪器大类">
                <el-option label="正常" value="1"></el-option>
                <el-option label="维修" value="2"></el-option>
                <el-option label="不可用" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="预约形式:" prop="order_type" label-width="85px">
              <el-select class="com-select110" v-model="ruleForm.order_type" placeholder="仪器大类">
                <el-option label="免预约" value="1"></el-option>
                <el-option label="必须预约" value="2"></el-option>
                <el-option label="可不预约" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
        </div>
      </div>
      <div class="module">
        <h3 class="title">仪器主要功能及其描述：</h3>
        <div class="content">
          <el-form-item label="服务领域:">
            <el-input
              class="com-input60"
              type="textarea"
              v-model="ruleForm.service_industry"
              placeholder="请输入文字"
            ></el-input>
          </el-form-item>
          <el-form-item label="技术指标:">
            <el-input
              class="com-input60"
              type="textarea"
              v-model="ruleForm.tech_index"
              placeholder="请输入文字"
            ></el-input>
          </el-form-item>
          <el-form-item label="主要功能及其领域:">
            <el-input
              class="com-input60"
              type="textarea"
              v-model="ruleForm.main_function"
              placeholder="请输入文字"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="module">
        <h3 class="title">服务信息:</h3>
        <div class="content">
          <el-row>
            <el-col :span="8">
              <el-form-item label="仪器联系人:" prop="user_name">
                <el-input class="com-input60" v-model="ruleForm.user_name"></el-input>
              </el-form-item>
              <el-form-item label="开放机时安排:" prop="open_plan">
                <el-input class="com-input90" v-model="ruleForm.open_plan"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人电话:" prop="user_mobile">
                <el-input class="com-input60" type="number"  v-model="ruleForm.user_mobile" @mousewheel.native.prevent></el-input>
              </el-form-item>
              <el-form-item label="参考收费标准:" prop="charge">
                <!-- <el-button type="primary" icon="">¥</el-button> -->
                <el-input class="com-select80" v-model="ruleForm.charge"></el-input>/
                <el-select class="com-select80" v-model="ruleForm.charge_unit">
                  <el-option label="件" value="件"></el-option>
                  <el-option label="次" value="次"></el-option>
                  <el-option label="重量" value="重量"></el-option>
                  <el-option label="时间" value="时间"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱:" prop="email">
                <el-input class="com-input60" v-model="ruleForm.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-form-item style="margin-top:15px;">
        <el-button type="danger" @click="$router.go(-1)">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
      <!-- 自定义仪器分类 -->
      <el-dialog
        :close-on-click-modal="false"
        title="自定义仪器分类"
        :visible.sync="myOderDiagbox"
        width="30%"
        top="15vh"
        center
      >
        <div class="myDiagbox">
          <el-button type="text" size="mini" @click="addCate">添加仪器大类</el-button>
          <div v-for="(item,i) in myOrderList" :key="i">
            <div>
              {{item.name}}
              <el-button type="text" size="mini" @click="addMiddle(i,item.id)">添加中类</el-button>
            </div>
            <div style="margin-left:40px;" v-for="(item1,i1) in item.children" :key="i1">
              <div>
                {{item1.name}}
                <el-button type="text" size="mini" @click="addMini(i,i1,item.id,item1.id)">添加小类</el-button>
              </div>
              <div style="margin-left:40px;" v-for="(item2,i2) in item1.children" :key="i2">
                <div>{{item2.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="myOderDiagbox = false">取 消</el-button>
          <el-button type="primary" @click="addOrder">添 加</el-button>
        </span>
      </el-dialog>
    </el-form>
  </div>
</template>
<script>
import { success, warning, error } from "@/utils/notification";
export default {
  data() {
    return {
      one: {},
      two: {},
      three: {},
      category: [], //分类显示
      ruleForm: {
        organ_id: 0,
        code: "",
        name: "",
        value_unit: "元",
        work_status: "1", //	工作状态
        order_type: "1", //	预约形式
        charge_unit: "次", //收费标准单位
        image: "", //仪器图片
        big_category_id: 0,
        second_category_id: 0,
        small_category_id: 0,
        category: [] //分类显示
      },
      myOderDiagbox: false, //我自定义分类弹窗
      myOrderList: [], //自定义的列表内容
      instCategories: [], //仪器分类数据
      props: { label: "name", value: "id" }, //级联配置
      rules: {
        code: [{ max: 10, message: "不超过10个字符", trigger: "blur" }],
        name: [
          { required: true, message: "请输入仪器名称", trigger: "blur" },
          { max: 60, message: "不超过60个字符", trigger: "blur" }
        ],
        company: [
          { required: true, message: "请输入仪器的所属单位", trigger: "blur" },
          { max: 60, message: "不超过60个字符", trigger: "blur" }
        ],
        english_name: [{ max: 60, message: "不超过60个字符", trigger: "blur" }],
        model: [{ max: 20, message: "不超过20个字符", trigger: "blur" }],
        manufacturer: [{ max: 60, message: "不超过60个字符", trigger: "blur" }],
        image: [{ required: true, message: "请上传仪器图片", trigger: "blur" }],
        address: [
          { required: true, message: "请输入仪器的安放地址", trigger: "blur" },
          { max: 60, message: "不超过60个字符", trigger: "blur" }
        ],
        country: [{ max: 60, message: "不超过60个字符", trigger: "blur" }],
        value: [
          { required: true, message: "请输入仪器的原值", trigger: "blur" }
        ],
        work_status: [
          { required: true, message: "请选择工作状态", trigger: "change" }
        ],
        order_type: [
          { required: true, message: "请选择预约形式", trigger: "change" }
        ],
        get_date: [
          {
            type: "string",
            required: true,
            message: "请选择购置日期",
            trigger: "change"
          }
        ],
        user_name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          { max: 20, message: "不超过20个字符", trigger: "blur" }
        ],
        user_mobile: [
          { required: true, message: "请输入联系人电话", trigger: "blur" }
        ],
        open_plan: [
          { required: true, message: "请输入开放机时安排", trigger: "blur" }
        ],
        category: [
          { required: true, message: "请选择仪器分类", trigger: "change" }
        ]
      }
    };
  },
  created() {
    this.ruleForm.organ_id = sessionStorage.organizationId;
    this.getInstCategory(this.ruleForm.organ_id);
    this.$route.query.id &&
      this.getDetails(this.$route.query.id, this.ruleForm.organ_id);
  },
  methods: {
    //仪器分类级联选择
    categoryChange(e) {
      console.log(e, "categoryChangecategoryChange");
      if (e) {
        this.ruleForm.big_category_id = e[0];
        this.ruleForm.second_category_id = e[1];
        this.ruleForm.small_category_id = e[2];
      }
    },
    //保存
    save() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$route.query.id ? this.edit(this.$route.query.id) : this.add();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //新增
    async add() {
      const { data: res } = await this.$http.post(
        this.URL.adminUrl.instruments,
        this.ruleForm
      );
      success("添加仪器信息成功");
      this.$router.push("/sendInstrument");
      console.log(res, "保存保存");
    },
    //编辑
    async edit(id) {
      const { data: res } = await this.$http.put(
        this.URL.adminUrl.instruments + id,
        this.ruleForm
      );
      success("编辑仪器信息成功");
      this.$router.push("/sendInstrument");
      console.log(res, "保存保存");
    },

    //上传图片前
    beforeUpload(file) {
      console.log(file, "上传图片前");
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        warning("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    //图片上传成功
    uploadSuccess(e) {
      console.log(e, "22");

      this.ruleForm.image = e.data.path;
    },
    //科研仪器分类
    async getInstCategory(organ_id) {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.instCategory,
        {
          params: { organ_id }
        }
      );
      this.instCategories = res.data;
      console.log(res.data, "科研仪器分类");
    },
    //科研仪器详情
    async getDetails(id, organ_id) {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.instruments + id,
        {
          params: { organ_id }
        }
      );
      this.ruleForm = res.data;
      this.ruleForm.work_status = this.ruleForm.work_status + "";
      this.ruleForm.order_type = this.ruleForm.order_type + "";
      this.ruleForm.category = [
        this.ruleForm.big_category.id,
        this.ruleForm.second_category.id,
        this.ruleForm.small_category.id
      ];
    },
    //我的自定义分类
    myOrder() {
      let arr = [];
      this.instCategories.forEach(item => {
        item.is_default == 0 && arr.push(item);
      });
      this.myOrderList = arr;
      this.myOderDiagbox = true;
    },
    async addOrder() {
      console.log(this.one);
      console.log(this.two);
      console.log(this.three);
      const { data: res } = await this.$http.post(
        this.URL.adminUrl.instCategory,
        {
          organ_id: this.ruleForm.organ_id,
          one: this.one,
          two: this.two,
          three: this.three
        }
      );
      success("自定义添加成功");
      this.myOderDiagbox = false;
      this.getInstCategory(this.ruleForm.organ_id);
    },
    addCate() {
      this.$prompt("请输入添加的大类", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({ value }) => {
        this.one = { id: 0, name: value };
        this.myOrderList.push({ id: 0, name: value, children: [] });
      });
    },
    addMiddle(i, id) {
      this.$prompt("请输入添加的中类", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({ value }) => {
        if (id != 0) {
          this.one = { id };
        }
        this.two = { id: 0, name: value };
        this.myOrderList[i].children.push({ id: 0, name: value, children: [] });
      });
    },
    addMini(i, i1, id, id1) {
      console.log(i, i1, id, id1);
      this.$prompt("请输入添加的小类", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({ value }) => {
        if (id != 0) {
          this.one = { id: id };
        }
        if (id1 != 0) {
          this.two = { id: id1 };
        }
        this.three = { id: 0, name: value };
        this.myOrderList[i].children[i1].children.push({ id: 0, name: value });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.add-instrument {
  padding: 10px 5px;
  .back-btn {
    //返回键
    margin: 0 20px;
    font-size: 18px;
  }
  .module {
    .title {
      margin: 20px 0 10px;
    }
    .content {
      border: 1px solid rgb(209, 203, 203);
      padding: 20px 0;
      border-radius: 10px;
    }
  }
  //自定义仪器分类
  .myDiagbox {
    margin-left: 30%;
    font-size: 15px;
  }

  // 公共样式
  .com-input60 {
    width: 60%;
  }
  .com-input90 {
    width: 90%;
  }
  .com-select80 {
    width: 80px;
    margin: 0 1px;
  }
  .com-select100 {
    width: 100px;
    margin: 0 1px;
  }
  .com-select110 {
    width: 110px;
    margin: 0 1px;
  }
}
</style>
<style >
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
/* 上传图片样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
.image-tag {
  position: absolute;
  left: 160px;
  top: 10px;
  width: 150px;
  font-size: 13px;
  color: rgb(163, 161, 157);
}
</style>